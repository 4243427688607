.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiBox-root {
  max-width: 70px;
}

.MuiTableCell-paddingCheckbox {
  display: none !important;
}

svg {
  font-size: 1.1rem !important;
}

.RaMenuItemLink-active {
  color: #fff !important;
}

.layout.css-1r5redm-RaLayout-root {
  overflow-x: hidden;
}

.RaAppBar-menuButton:hover,
.RaAppBar-menuButton:focus {
  background-color: #bf2279 !important;
}

.RaAppBar-menuButton {
  padding-left: 10px !important;
  margin-left: 100px !important;
}

.css-1ghzyfa-RaLoadingIndicator-root {
  display: none;
}

.RaLayout-content {
  background-color: #f4f4f7;
}

.MuiTable-root.css-1owb465 {
  border-collapse: separate;
  border-spacing: 0 7px;
  overflow-x: hidden;
}

.MuiPaper-root.MuiDialog-paper {
  border-radius: 20px !important;
}

#alert-dialog-title {
  display: none;
}

.MuiDialogContent-root {
  padding-top: 40px !important;
}

.RaLayout-content {
  background-image: url(./assets/background.png);
  background-position: center;
  background-size: cover;
}

.RaLayout-content>div {
  background-color: #fff;
  opacity: 0.97;
  margin: 32px 10px;
  border-radius: 22px;
  padding: 0;
  min-height: 82vh;
  border: 1px solid #ebe7e7;
}

.RaList-main {
  background-color: #f4f6fb;
  min-height: 82vh;
  border-radius: 20px;
}

.RaList-main .MuiToolbar-gutters:not(.MuiTablePagination-toolbar) button {
  margin-bottom: 10px;
  margin-right: 35px;
  color: #fff;
  background: #bf2279;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 500;
  padding: 6px 30px;
  border-radius: 24px;
}

.RaList-main .MuiToolbar-root {
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background: linear-gradient(90deg, #bf2279 50%, #FF6D44 50%);
}

.RaList-main .MuiTablePagination-toolbar {
  background: transparent !important;
}


.RaList-main .MuiPaper-root {
  padding: 0 15px;
}

.MuiTablePagination-root p, .MuiTablePagination-root .MuiInputBase-root {
  display: none;
}

.MuiTablePagination-root {
  margin: 0 auto;
}

.MuiPaper-root.MuiDrawer-paper {
  width: 200px !important;
}

.MuiTableRow-root .MuiTableCell-root {
  line-height: 2.2;
}

.MuiTableRow-root .MuiTableCell-root .MuiBox-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column-undefined {
  max-width: 20px !important;
}

.column-undefined button:hover {
  background: transparent !important;
}

.column-logoUrl img {
  width: 100%;
}

.column-dateAdded {
  text-align: left;
}

.MuiTableCell-root.column-undefined button svg {
  color: #BF2279 !important;
}

.MuiTableCell-root.column-undefined button {
  min-width: 10px !important;
  color: #fff;
}

.MuiTableCell-root.column-undefined button:hover,
.MuiTableCell-root.column-undefined button:active {
  background-color: #fff !important;
  color: #fff;
}


.auth-section button {
  padding: 0 20px;
  height: 30px;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

@media (min-width: 600px) {
  .css-1kt0a20-MuiToolbar-root-RaListToolbar-root, .MuiToolbar-root {
    height: 50px !important;
    min-height: 50px !important;
    justify-content: space-around;
  }

  .RaBulkActionsToolbar-toolbar {
    display: none !important;
  }
}

.css-2btlu-MuiInputBase-root-MuiFilledInput-root:hover {
  background-color: #fff !important;
}

.RaDatagrid-headerCell {
  font-size: 13px !important;
}

.user-profile {
  padding-top: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #fff;
  padding-left: 15px;
  padding-bottom: 7px;
  min-width: 185px;
}

.user-profile img {
  width: 55px;
}

.user-name {
  display: flex;
  flex-direction: column;
  color: #fff;
  margin-left: 10px;
}

.user-last-name {
  text-transform: capitalize;
  font-size: 13px;
}

.sidebar-open {
  transition: 0.6s;
  width: 220px !important;

}

.sidebar-closed{
  transition: 0.6s;
  width: 50px !important;
}

.RaSidebar-fixed {}



.RaSidebar-fixed {
  background-color: #BF2279;
}

.MuiButton-startIcon {
  margin-right: 5px !important;
}

.MuiTableRow-root td:last-child {
  padding-right: 20px;
}

.MuiTableRow-root td:nth-child(2) {
  padding-left: 20px !important;
}

.MuiToolbar-root .MuiButton-startIcon svg {
  font-size: 13px !important;
}

.css-qbf13a {
  display: none;
}


.MuiPaginationItem-root svg {
  color: #BF2279;
}

.MuiPaginationItem-root.Mui-selected {
  background-color: #fff !important;
  color: #BF2279;
}

.MuiButtonBase-root.MuiPaginationItem-root:focus::after {
  background-color: transparent !important;
}

.ra-input-isVerified p, .ra-input-isPartner p {
  display: none;
}

.MuiDialog-container .MuiToolbar-root {
  background-color: #fff;
  padding-bottom: 50px;
}

.BaseBadge-root svg {
  width: 24px;
  height: 23px;
}

.BaseBadge-root .MuiBadge-dot {
  background-color: #FF6D44;
  top: 7px;
  right: 7px;
}

.notifications-link {
  color: #fff !important;
}

textarea {
  background: #fff !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 41px;
  padding: 10px !important;
  margin-left: 95px !important;

}

.MuiTableCell-root {
  padding: 6px 12px !important;
}

.MuiSwitch-switchBase.Mui-checked {
  color: #fff !important;
}

.MuiSwitch-switchBase.Mui-checked .MuiSwitch-track {
  background-color: #BF2279 !important;

}

.MuiSwitch-root .Mui-checked+.MuiSwitch-track {
  background-color: #BF2279 !important;
}

::-webkit-scrollbar {
  width: 10px;
  padding-top: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b9b8b8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(209, 206, 206);
}

.RaEdit-noActions {
  margin-top: 0 !important;
}

.MuiBackdrop-root {
  opacity: 0.5 !important;
}

.MuiFilledInput-root input::after {
  border-bottom: none !important;
}

.MuiTableCell-head {
  border-bottom: none !important;
}

.array-list {
  list-style: none;
  display: block;
  max-width: 150px !important;
  width: 150px !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shorter-text {
  display: block;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.column-categories {
  max-width: 150px !important;
  width: 150px !important;
  text-overflow: ellipsis;
}

.css-10p50sx-MuiButtonBase-root-MuiMenuItem-root-RaMenuItemLink-root.RaMenuItemLink-active,
.css-10p50sx-MuiButtonBase-root-MuiMenuItem-root-RaMenuItemLink-root.RaMenuItemLink-active,
.css-10p50sx-MuiButtonBase-root-MuiMenuItem-root-RaMenuItemLink-root.RaMenuItemLink-active,
.css-10p50sx-MuiButtonBase-root-MuiMenuItem-root-RaMenuItemLink-root.RaMenuItemLink-active,
.css-1kpj4ej-MuiButtonBase-root-MuiMenuItem-root-RaMenuItemLink-root.RaMenuItemLink-active,
.css-1kpj4ej-MuiButtonBase-root-MuiMenuItem-root-RaMenuItemLink-root.RaMenuItemLink-active {
  font-weight: 600 !important;
}

.RaMenuItemLink-active {
  font-weight: 600 !important;
}

.MuiMenuItem-root {
  margin: 0 !important;
}

.shorter-text {
  display: block;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shorter-text-small {
  display: block;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0 !important;
}

.shorter-text-extra-small {
  display: block;
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0 !important;
}

.shorter-text-offer {
  display: block;
  width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0 !important;
}

.shorter-text-wishlist {
  display: block;
  width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0 !important;
}

.shorter-text-partners {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0 !important;
}

.top-menu-list {
  display: flex;
  width: 70%;
}

.top-menu-list .MuiMenuItem-root {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.RaList-main {
  position: relative;
}

.RaList-main>.MuiToolbar-regular {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}

.RaDatagrid-tableWrapper {
  margin-top: 54px !important
}

.MuiTableBody-root tr td {
  background-color: #fff;
}

.auth-section-wrapper {
  display: flex;
}

.list-page .column-backgroundImageUrl .RaImageField-image:hover {
  transform: scale(9);
  z-index: 3 !important;
  border: 1px solid #efe8e8 !important;
  border-radius: 3px !important;
  position: absolute;
  top: 129px;
  left: 160px
}

.RaDatagrid-headerCell {
  background: #F4F6FB !important;
}


.MuiInputAdornment-root button {
  padding: 0 !important;
  margin: 0 !important;
  font-size: medium !important;
  text-transform: none !important;

}

.RaResettableTextField-clearButton {
  height: auto !important;
  width: auto !important;
}

@media(max-width:1380px) {
  .shorter-text {
    display: block;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .shorter-text-extra-small {
    display: block;
    width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 0 !important;
  }

  .shorter-text-small {
    display: block;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 0 !important;
  }

  .shorter-text-offer {
    display: block;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 0 !important;
  }

  .shorter-text-wishlist {
    display: block;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 0 !important;
  }

  .shorter-text-partners {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 0 !important;
  }

  .MuiTableCell-root {
    padding: 6px 6px !important;
  }

  .column-categories {
    max-width: 100px !important;
    width: 100px !important;
  }

  .array-list {
    max-width: 100px !important;
    width: 100px !important;
  }

  .RaAppBar-menuButton {
    margin-left: 100px !important;
  }

  .list-page .column-backgroundImageUrl .RaImageField-image:hover {
    transform: scale(7);
    z-index: 3 !important;
    border: 1px solid #efe8e8 !important;
    border-radius: 3px !important;
    position: absolute;
    top: 90px;
    left: 140px
  }


}